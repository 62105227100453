/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://lowpoooq25cn3blyj3hf6f7z3a.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qpv2kftlvvcznmtabiexo45xf4",
    "aws_cognito_identity_pool_id": "us-east-2:5d0d266a-e937-4d75-8a63-1d3601c4d966",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_yWoz2dKxl",
    "aws_user_pools_web_client_id": "47sk5tnbhd4lastuud6qbvm28",
    "oauth": {}
};


export default awsmobile;
