import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react'
import { DataStore } from '@aws-amplify/datastore';
import { Appointment } from './models';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    const pullData = async() => {
      const models = await DataStore.query(Appointment);
      console.log(models);
    }

    pullData();
  }, [])

  const addAppointment = async() => {
    await DataStore.save(
      new Appointment({
      "month": 12,
      "day": 5,
      "year": 2021,
      "type": "Vaccine Shot",
      "notes": "No Notes",
      "patient_id": 1
    })
    );
  }

  return (
    <div className="App">
      <button onClick={addAppointment}>Add Appointment</button>
    </div>
  );
}

export default withAuthenticator(App)
